import React, {useState} from 'react'
import Constants from '../utilities/Constants'

export default function PostUpdateForm(props) {

  const initialFormData = Object.freeze({
    title: props.post.title,
    content: props.post.content
  });

  const [ formData, setFormData ] = useState(initialFormData);

  const handleChange =(e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const postToUpdate = {
      postId: props.post.postId,
      title: formData.title,
      content: formData.content
    };
    
    const url = Constants.API_URL_UPDATE_POST;

    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postToUpdate)
    } ).then(response => response.json())
    .then(responseFromServer => {
      console.log(responseFromServer);
    })
    .catch((err) => {
      console.log(err)
      alert(err)
    })
    props.onPostUpdated(postToUpdate)
  }


  return (
    
      <form className='w-100 px-5'>
        <h1>Update {props.post.title} that has the id of {props.post.postId}</h1>

        <div className='mt-5'>
          <label className='h3 form-label'></label>
          <input value={formData.title} name='title' type='text' className='form-control' onChange={handleChange} />
        </div>

        <div className='mt-5'>
          <label className='h3 form-label'></label>
          <input value={formData.content} name='content' type='text' className='form-control' onChange={handleChange} />
        </div>

        <div className='mt-5'>
        <button onClick={handleSubmit} className='btn btn-dark btn-lg m-3'>Submit</button>
        <button onClick={() => props.onPostUpdated(null)} className='btn btn-dark btn-lg m-3'>Cancel</button>
        </div>

      </form>

  )
}

