import React, {useState} from 'react'
import Constants from '../utilities/Constants'

export default function PostCreateForm(props) {

  

  const initialFormData = Object.freeze({
    title: "Post x",
    content: "This is post x has very interesting content"
  });

  const [ formData, setFormData ] = useState(initialFormData);

  const handleChange =(e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const postToCreate = {
      postId: 0,
      title: formData.title,
      content: formData.content
    };
    const url = Constants.API_URL_CREATE_POST;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postToCreate)
    } ).then(response => response.json())
    .then(responseFromServer => {
      console.log(responseFromServer);
      // setPost(postsFromServer)
    })
    .catch((err) => {
      console.log(err)
      alert(err)
    })
    props.onPostCreated(postToCreate)

  }


  return (
    
      <form className='w-100 px-5'>
        <h1>Create new post</h1>

        <div className='mt-5'>
          <label className='h3 form-label'></label>
          <input value={formData.title} name='title' type='text' className='form-control' onChange={handleChange} />
        </div>

        <div className='mt-5'>
          <label className='h3 form-label'></label>
          <input value={formData.content} name='content' type='text' className='form-control' onChange={handleChange} />
        </div>

        <div className='mt-5'>
        <button onClick={handleSubmit} className='btn btn-dark btn-lg m-3'>Submit</button>
        <button onClick={() => props.onPostCreated(null)} className='btn btn-dark btn-lg m-3'>Cancel</button>
        </div>

      </form>

  )
}

