import { useState } from "react";
import Constants from './utilities/Constants'
import PostCreateForm from "./components/PostCreateForm";
import PostUpdateForm from './components/PostUpdateForm';
import "./App.css";

export default function App() {
  const [ posts, setPost ] =useState([])
  const [ showingCreateNewPostForm, setShowingCreateNewPostForm ] = useState(false)
  const [ postCurrentlyBeingUpdated, setPostCurrentlyBeingUpdated ] = useState(null)

  function getPosts(){
    const url = Constants.API_URL_GET_ALL_POSTS
    fetch(url, {
      method: 'GET'
    } ).then(response => response.json())
    .then(postsFromServer => {
      console.log(postsFromServer);
      setPost(postsFromServer)
    })
    .catch((err) => {
      console.log(err)
      alert(err)
    })
  }

  function deletePost(postId) {
    const url = `${Constants.API_URL_DELETE_POST_BY_ID}/${postId}`
    fetch(url, {
      method: 'DELETE'
    } ).then(response => response.json())
    .then(responseFromServer => {
      console.log(responseFromServer);
      onPostDeleted(postId);
      // setPost(responseFromServer)
    })
    .catch((err) => {
      console.log(err)
      alert(err)
    })
  }

  return (
    <div className="container">
      <div className="row min-vh-100">
        <div className="col d-flex flex-column justify-content-center align-items-center">
          
          {(showingCreateNewPostForm === false && postCurrentlyBeingUpdated === null) && (
            <div>
              <h1>APS.NET/REACT</h1>
              <div className="mt-5">
                <button
                  className="btn btn-dark btn-large w-100"
                  onClick={getPosts}
                >
                  Get posts
                </button>
                <button
                  className="btn btn-secondary btn-large w-100"
                  onClick={() => setShowingCreateNewPostForm(true)}
                >
                  Create post
                </button>
              </div>
            </div>
          )}

          {(posts.length > 0 &&  showingCreateNewPostForm === false && postCurrentlyBeingUpdated === null) &&  renderPostTable()}

          {showingCreateNewPostForm && (
            <PostCreateForm onPostCreated={onPostCreated} />
          )}

          {postCurrentlyBeingUpdated !== null && < PostUpdateForm post={postCurrentlyBeingUpdated} onPostUpdated={onPostUpdated} />}
        </div>
      </div>
    </div>
  );

  function renderPostTable() {
    return (
      <div className="table-responsive mt-5">
        <table className="table table-bordered border-dark">
          <thead>
            <tr>
              <th scope="col">PostId (PK)</th>
              <th scope="col">Title</th>
              <th scope="col">Content</th>
              <th scope="col">CRUD operations</th>
            </tr>
          </thead>
          <tbody>
            {posts.map((post) => (
              <tr key={post.postId}>
                <th scope="row">{post.postId}</th>  
                <td>{post.title}</td>
                <td>{post.content}</td>
                <td>

                  <button onClick={() => setPostCurrentlyBeingUpdated(post)} className="btn btn-dark btn-lg">Update</button>

                  <button onClick={() => { if(window.confirm(`Are you sure you want to delete the post titled ${post.title}?`)) deletePost(post.postId) }} className="btn btn-secondary btn-lg">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <button onClick={() => setPost([]) } className='btn btn-dark btn-lg w-100'>Empty</button>
      </div>
    );
  }

  function onPostCreated(createdPost) {
    setShowingCreateNewPostForm(false)

    if (createdPost === null) {
      return;
    } else {
      alert(`Post successfully created after clicking ok. Your new post titled ${createdPost.title} will show up in the table below`)
    }
  }

  function onPostUpdated(updatedPost) {
    setPostCurrentlyBeingUpdated(null);

    if(updatedPost === null){
      return;
    }
    let postsCopy = [...posts];
    // eslint-disable-next-line array-callback-return
    const index = postsCopy.findIndex((postsCopyPost, currentIndex) => {

      if(postsCopyPost.postId === updatedPost.postId){
        return true;
      }  
    });

    if(index !== -1){
      postsCopy[index] = updatedPost;
    }

    setPost(postsCopy);
     alert(`Your post has been updated after clicking OK. Look for updated title ${updatedPost.title} in the table below`)
  }

  
  function onPostDeleted(deletedPostPostId) {
  
    let postsCopy = [...posts];
    // eslint-disable-next-line array-callback-return
    const index = postsCopy.findIndex((postsCopyPost, currentIndex) => {

      if(postsCopyPost.postId === deletedPostPostId){
        return true;
      }  
    });

    if(index !== -1){
      postsCopy.splice(index, 1);
    }

    setPost(postsCopy);
     alert('Post deleted')
  }

}
